// src/App.js
import React from 'react';
import { Amplify } from 'aws-amplify';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import awsconfig from './aws-exports';
import HomePage from './HomePage';
import RegistrationPage from './RegistrationPage';
import SubmitData from './SubmitData';
import SubmitChunks from './SubmitChunks';
import SubmitTFTask from './SubmitTFTask';
import Dashboard from './Dashboard';
import ResultsPage from './ResultsPage';
import '@aws-amplify/ui-react/styles.css';
import './App.css';

Amplify.configure(awsconfig);

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/register" element={<RegistrationPage />} />
          <Route path="/submitdata" element={<SubmitData />} />
          <Route path="/submitchunks" element={<SubmitChunks />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/results/:batchId" element={<ResultsPage />} />
          <Route path="/submit-tf-task" element={<SubmitTFTask />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
