import React, { useState, useEffect } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { fetchAuthSession } from '@aws-amplify/auth';
import { useParams } from 'react-router-dom';
import './ResultsPage.css';

Amplify.configure(awsconfig);

function ResultsPage() {
  const { batchId } = useParams();
  const [results, setResults] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchResults();
  }, [batchId]);

  const fetchResults = async () => {
    try {
      const session = await fetchAuthSession();
      const idToken = session.tokens.idToken.toString();

      const response = await fetch(`https://f3dtih3bqe.execute-api.us-east-1.amazonaws.com/dev/result?batchId=${batchId}`, {
        headers: {
          Authorization: idToken,
        },
      });

      console.log(`Response Status: ${response.status}`); // Log response status

      if (!response.ok) {
        const errorText = await response.text(); // Get response text for more detailed error
        console.log(`Response Error: ${errorText}`);
        throw new Error(`HTTP error! status: ${response.status}, body: ${errorText}`);
      }

      const data = await response.json();
      console.log('API response:', data); // Log the response
      setResults(data);
    } catch (error) {
      console.error('Error fetching results:', error);
      setMessage('Error fetching results');
    }
  };

  const formatDateTime = (dateString) => {
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { hour: 'numeric', minute: 'numeric' }; // Removed seconds
    const date = new Date(dateString);
    return {
      date: date.toLocaleDateString(undefined, dateOptions),
      time: date.toLocaleTimeString(undefined, timeOptions)
    };
  };

  const renderTaskMetadata = (metadata) => {
    if (!metadata) return null;

    let parsedMetadata;
    try {
      parsedMetadata = JSON.parse(metadata);
    } catch (error) {
      console.error('Error parsing taskMetadata:', error);
      return null;
    }

    return (
      <>
        <div>Target Variable: {parsedMetadata.targetVariable || 'N/A'}</div>
        <div>Columns: {parsedMetadata.numColumns || 'N/A'}</div>
      </>
    );
  };

  const renderResults = (results) => {
    if (!results) return null;

    return Object.entries(results).map(([key, value]) => (
      <div key={key}>{key}: {Array.isArray(value) ? value.join(', ') : value}</div>
    ));
  };

  return (
    <div className="results-page">
      <div className="results-heading">
        <h2>Riddle: Results</h2>
      </div>
      {message && <p>{message}</p>}
      {results ? (
        <div className="results-details">
          <div>Batch ID: {results.batchId}</div>
          <div>File Name: {results.fileName}</div>
          <div>Status: {results.status}</div>
          <div>Upload Date: {formatDateTime(results.uploadDate).date}</div>
          <div>Upload Time: {formatDateTime(results.uploadDate).time}</div>
          <div>Processed Date: {results.processedDate ? formatDateTime(results.processedDate).date : 'N/A'}</div>
          <div>Processed Time: {results.processedDate ? formatDateTime(results.processedDate).time : 'N/A'}</div>
          <div className="separator"></div>
          <div>Task Type: {results.taskType}</div>
          {renderResults(results.results)}
          {renderTaskMetadata(results.taskMetadata)}
        </div>
      ) : (
        !message && <p>Loading...</p>
      )}
    </div>
  );
}

export default withAuthenticator(ResultsPage);
