// src/RegistrationPage.js
import React from 'react';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './RegistrationPage.css';

Amplify.configure(awsconfig);

function RegistrationPage() {
  return (
    <div className="registration-page">
      <div className="registration-heading">
        <h2>Riddle Distributed Computing</h2>
      </div>
      <div className="registration-form">
        <Authenticator>
          {({ signOut, user }) => (
            <main>
              <h1>Welcome!</h1>
              <button onClick={signOut}>Sign out</button>
            </main>
          )}
        </Authenticator>
      </div>
    </div>
  );
}

export default RegistrationPage;
