// src/HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css';

function HomePage() {
  return (
    <div className="home-page">
      <div className="welcome">
        <h1>Welcome to Riddle Distributed Computing</h1>
      </div>
      <div className="features-container">
        <Link to="/register" className="feature">
          <h2>Sign Up</h2>
          <p>Create an account to submit tasks</p>
        </Link>
        <Link to="/submitdata" className="feature">
          <h2>Compute</h2>
          <p>Use our distributed network</p>
        </Link>
        <Link to="/pricing" className="feature">
          <h2>Pricing</h2>
          <p>Flat rate and dynamic models</p>
        </Link>
        <Link to="/dashboard" className="feature">
          <h2>Dashboard</h2>
          <p>Check your results</p>
      
        </Link>
      </div>
    </div>
  );
}

export default HomePage;
