import React, { useState } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import { uploadData } from 'aws-amplify/storage';
import awsconfig from './aws-exports';
import { fetchAuthSession } from '@aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';
import './SubmitData.css';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';

Amplify.configure(awsconfig);

function SubmitData() {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [batchId, setBatchId] = useState(null); // Add this state to store the batch ID
  const [taskType, setTaskType] = useState('linear-regression');
  const [taskMetadata, setTaskMetadata] = useState({});
  const [columns, setColumns] = useState([]);
  const [isUploading, setIsUploading] = useState(false); // State to track upload status
  const [uploadProgress, setUploadProgress] = useState(0); // State to track upload progress

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const firstLine = text.split('\n')[0];
        const cols = firstLine.split(',').map(col => col.trim());
        setColumns(cols);

        // Update taskMetadata to include number of columns
        setTaskMetadata(prevMetadata => ({ ...prevMetadata, numColumns: cols.length }));
      };
      const blob = file.slice(0, 1024); // Read only the first 1024 bytes to get the header
      reader.readAsText(blob);
    }
  };

  const handleTaskTypeChange = (event) => {
    setTaskType(event.target.value);
    setTaskMetadata({}); // Reset metadata when task type changes
  };

  const handleTaskMetadataChange = (field, value) => {
    setTaskMetadata(prevMetadata => ({ ...prevMetadata, [field]: value }));
  };

  const handleFileUpload = async () => {
    if (!file) {
      setMessage('Please select a file to upload');
      return;
    }

    setIsUploading(true); // Set uploading status to true

    try {
      const session = await fetchAuthSession();
      const idToken = session.tokens.idToken.toString();
      const sub = session.tokens.idToken.payload.sub;
      const owner = sub;
      const newBatchId = uuidv4();  // Generate a unique batch ID
      setBatchId(newBatchId); // Store the batch ID in state

      const metadata = {
        taskType: taskType,
        taskMetadata: JSON.stringify({ ...taskMetadata, numColumns: columns.length })
      };

      // Log the metadata being sent along with the batch ID
      console.log('Metadata being sent:', metadata, 'Batch ID:', newBatchId);

      const uploadResult = await uploadData({
        key: `private/${owner}/${newBatchId}/${file.name}`,
        data: file,
        options: {
          level: 'private',
          contentType: 'text/csv',
          metadata: metadata, // Include metadata in the options
          progressCallback(progress) {
            setUploadProgress(Math.round((progress.loaded / progress.total) * 100));
          },
        },
      }).result;

      const dataBatchDetails = {
        fileName: file.name,
        s3Location: uploadResult.key,
        uploadDate: new Date().toISOString(),
        status: 'UPLOADED',
        owner: owner,
        batchId: newBatchId,  // Include batch ID
        taskType: taskType,
        taskMetadata: taskMetadata,
      };

      console.log('Creating DataBatch with details:', dataBatchDetails);
      setMessage(`File uploaded and record created: ${file.name}`);
    } catch (error) {
      // Log detailed error information
      console.error('Error uploading file:', error);
      const errorMessage = error.message || JSON.stringify(error);
      const errorResponse = error.response ? JSON.stringify(error.response) : 'No response';
      const errorRequest = error.request ? JSON.stringify(error.request) : 'No request';
      setMessage(`Error uploading file: ${errorMessage}\nResponse: ${errorResponse}\nRequest: ${errorRequest}`);
    } finally {
      setIsUploading(false); // Reset uploading status
      setUploadProgress(0); // Reset upload progress
    }
  };

  const renderTaskMetadataFields = () => {
    if (taskType === 'linear-regression') {
      return (
        <>
          <div className="form-group">
            <label htmlFor="target-variable">Target Variable</label>
            <select
              id="target-variable"
              value={taskMetadata.targetVariable || ''}
              onChange={(e) => handleTaskMetadataChange('targetVariable', e.target.value)}
              required
            >
              <option value="">Select Target Variable</option>
              {columns.map((col) => (
                <option key={col} value={col}>
                  {col}
                </option>
              ))}
            </select>
          </div>
        </>
      );
    }
    // Add more task type conditions and corresponding metadata fields as needed
    return null;
  };

  return (
    <div className="submit-task-page">
      <div className="submit-task-heading">
        <h2>Riddle: Submit Data</h2>
      </div>
      <div className="submit-task-form">
        <div className="form-group centered">
          <label htmlFor="file-upload">Upload Dataset</label>
          <input
            type="file"
            id="file-upload"
            accept=".csv"
            onChange={handleFileChange}
            required
          />
        </div>

        <div className="sub-box"> {/* Sub-box for Number of Columns, Task Type, and Target Variable */}
          <div className="form-group">
            <label htmlFor="number-of-columns">Number of Columns: {columns.length}</label>
          </div>

          <div className="form-group">
            <label htmlFor="task-type">Task Type</label>
            <select id="task-type" value={taskType} onChange={handleTaskTypeChange} required>
              <option value="linear-regression">Linear Regression</option>
              <option value="task-type-2">Task Type 2</option>
              {/* Add more task types as needed */}
            </select>
          </div>

          {renderTaskMetadataFields()}
        </div>

        <div className="form-group centered">
          <button onClick={handleFileUpload} disabled={isUploading}>
            {isUploading ? `Uploading... ${uploadProgress}%` : 'Upload'}
          </button>
        </div>
        {message && <p>{message}</p>}
        {batchId && (
          <p>
            <Link to={`/results/${batchId}`}>Check Status</Link>
          </p>
        )}
      </div>
    </div>
  );
}

export default withAuthenticator(SubmitData);